var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.formData.id ? "更新App" : "新增App",
        visible: _vm.visible,
        width: "560px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "Form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "APP名称：", prop: "appName" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: !!_vm.formData.id,
                      placeholder: "请输入app名称",
                    },
                    model: {
                      value: _vm.formData.appName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "appName", $$v)
                      },
                      expression: "formData.appName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "版本编号：", prop: "appVersion" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入app版本编号" },
                    model: {
                      value: _vm.formData.appVersion,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "appVersion", $$v)
                      },
                      expression: "formData.appVersion",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "更新内容：", prop: "explain" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入更新内容" },
                    model: {
                      value: _vm.formData.explain,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "explain", $$v)
                      },
                      expression: "formData.explain",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "强制更新：" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-color": "#13ce66",
                      "inactive-color": "#E3E3E3",
                    },
                    model: {
                      value: _vm.formData.mustReplace,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "mustReplace", $$v)
                      },
                      expression: "formData.mustReplace",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "更新至当前版本：" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-color": "#13ce66",
                      "inactive-color": "#E3E3E3",
                    },
                    model: {
                      value: _vm.formData.replaceCurrentVersion,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "replaceCurrentVersion", $$v)
                      },
                      expression: "formData.replaceCurrentVersion",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "APP文件：", prop: "appPath" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        drag: "",
                        action: "",
                        accept: ".apk",
                        "file-list": _vm.fileList,
                        "on-change": _vm.change,
                        "show-file-list": false,
                        "http-request": _vm.uploadAppFile,
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v(" 将.apk 文件拖到此处，或 "),
                        _c("em", [_vm._v("点击上传")]),
                      ]),
                      _vm.fileList.length > 0
                        ? _c("div", {
                            staticClass: "progress",
                            style: { width: _vm.progressWidth + "%" },
                          })
                        : _vm._e(),
                      _vm.fileList.length > 0
                        ? _c("div", { staticClass: "fileName" }, [
                            _vm._v(_vm._s(_vm.fileList[0].name)),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleRepot } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }