<template>
  <!-- app版本管理 -->
  <div ref="charterOrder" class="charterOrder">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="queryForm"
        label-position="left"
        label-width="85px"
        class="demo-form-inline"
      >
        <el-form-item label="APP名称：">
          <el-input
            v-model="queryForm.appName"
            clearable
            placeholder="请输入APP名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="版本号：">
          <el-input
            v-model="queryForm.appVersion"
            clearable
            placeholder="请输入版本号"
          ></el-input>
        </el-form-item>

        <el-form-item label="强制更新：">
          <el-select
            v-model="queryForm.mustReplace"
            clearable
            placeholder="请选择是否强制更新"
          >
            <el-option label="强制更新" value="1"></el-option>
            <el-option label="自定义更新" value="0"></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="：">
          <el-select
            v-model="queryForm.orderStatusList"
            clearable
            placeholder="请选择启用状态"
          >
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>-->

        <el-form-item label="发布时间起：">
          <el-date-picker
            v-model="queryForm.startTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="dateTime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="发布时间止：">
          <el-date-picker
            v-model="queryForm.endTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="dateTime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button type="primary" size="small" @click="creatApp"
            >新建</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :operation="true"
      :table-height="tableHeight"
    >
      <template slot-scope="{ scopeRow }">
        <el-button type="text" size="small" @click="handleUpdate(scopeRow)"
          >更新</el-button
        >
        <el-button type="text" size="small" @click="handleDel(scopeRow)"
          >删除</el-button
        >
      </template>
    </Table>

    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <update-app ref="updateApp" @finsh="queryFun" />
  </div>
</template>

<script>
import { delApp, updateAppApi, getAppListByPage } from "@/api/lib/api.js";
import host from "@/api/static/host.js";
import updateApp from "./updateApp";
export default {
  components: { updateApp },
  data() {
    return {
      tableData: [],
      titleName: [
        {
          title: "APP名称",
          props: "appName",
        },
        {
          title: "版本号",
          props: "appVersion",
          width: 160,
        },
        {
          title: "下载链接",
          props: "appPath",
          render: (h, params, index) => {
            return h(
              "a",
              {
                class: "el-button--text",
                domProps: {
                  href: host.imgURL + params.appPath,
                  download: "广运神马",
                  target: "_blank",
                },
              },
              host.imgURL + params.appPath
            );
          },
        },
        {
          title: "更新内容",
          props: "explain",
        },
        {
          title: "强制更新",
          props: "mustReplace",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.mustReplace,
                activeValue: 1,
                inactiveValue: 0,
                activeColor: "#13ce66",
                inactiveColor: "#E3E3E3",
              },
              on: {
                change: (val) => {
                  params.mustReplace = val;
                  this.upadteVersion(params);
                },
              },
            });
          },
        },
        {
          title: "更新至当前版本",
          props: "replaceCurrentVersion",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.replaceCurrentVersion,
                activeValue: 1,
                inactiveValue: 0,
                activeColor: "#13ce66",
                inactiveColor: "#E3E3E3",
              },
              on: {
                change: (val) => {
                  console.log(val);
                  params.replaceCurrentVersion = val;
                  this.upadteVersion(params);
                },
              },
            });
          },
        },
        {
          title: "创建时间",
          props: "createTime",
        },
        {
          title: "更新时间",
          props: "updateTime",
        },
      ],
      total: 0,
      queryForm: {
        pageSize: 10,
        currentPage: 1,
      },
      form: {
        pageSize: 10,
        currentPage: 1,
      },
      tableHeight: 0,
    };
  },
  filters: {
    renderText(key, list = []) {
      let temp = list.find((item) => item.value == key) || {};
      return temp.label;
    },
  },
  mounted() {
    this.exportForm = this.deepClone(this.queryForm);
    this.renderData();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    //新增app
    creatApp() {
      this.$refs.updateApp.show({});
    },
    //更新app
    handleUpdate(row = {}) {
      this.$refs.updateApp.show(row);
    },
    //修改版本状态
    upadteVersion(params) {
      updateAppApi(params).then((res) => {
        this.renderData();
      });
    },
    //删除app
    handleDel({ id: appId }) {
      this.$confirm("此操作将永久删除该APP, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delApp({ appId }).then((res) => {
            if (res.code == "SUCCESS") {
              this.renderData();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 点击查询按钮的时候
    queryFun() {
      this.renderData();
    },

    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.charterOrder.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        36;
    },

    // 表格数据渲染
    renderData() {
      getAppListByPage(this.queryForm).then((res) => {
        if (res.code == "SUCCESS") {
          let list = res.data.list || [];
          this.tableData = [...list];
          this.total = res.data.total || 0;
        }
      });
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.queryForm.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.currentPage = 1;
      this.renderData();
    },
  },
};
</script>

<style scoped lang="scss">
.table-Popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  .content {
    position: absolute;
    z-index: 10000;
    background: #ffffff;
    border-radius: 10px;
    width: 1000px;
    padding: 0 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 1px;
    .row {
      height: 34px;
      width: 100%;
      line-height: 34px;
      margin: 20px 0;
      display: flex;
      .individual {
        display: flex;
        width: 33.3%;
        height: 100%;
        .label {
          width: 86px;
          padding-right: 14px;
          text-align: right;
          display: inline-block;
        }
        .row-val {
          padding: 0 10px;
          display: inline-block;
          width: calc(100% - 120px);
          border: 1px solid #d3d3d3;
          border-radius: 4px;
          color: #333333;
        }
      }
    }
    .header {
      padding: 18px 24px;
      height: 24px;
      width: calc(100% - 48px);
      border-bottom: 1px solid #d7d7d7;
      .text {
        margin-top: 8px;
        display: inline-block;
        font-size: 14px;
      }
      .close {
        float: right;
        margin-top: 10px;
        cursor: pointer;
        width: 12px;
        height: 12px;
        background: url("../../../assets/images/home/close.png") no-repeat;
        background-size: 100%;
        transform: translateY(-50%);
      }
    }
    .footer {
      padding: 10px 0px 18px 24px;
      height: 32px;
      width: calc(100% - 24px);
      border-bottom: none !important;
      border-top: 1px solid #d7d7d7;
      .el-button {
        float: right;
        margin-left: 14px;
      }
      .el-button:first-child {
        background: #336fee;
        color: #ffffff;
      }
    }
  }
}
.charterOrder {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
}
.Table {
  margin-top: 16px;
}
</style>
