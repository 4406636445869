<template>
  <el-dialog
    :title="formData.id ? '更新App' : '新增App'"
    :visible.sync="visible"
    width="560px"
    :close-on-click-modal="false"
  >
    <div>
      <el-form ref="Form" :model="formData" :rules="rules" label-width="130px">
        <el-form-item label="APP名称：" prop="appName">
          <el-input v-model="formData.appName" :disabled="!!formData.id" placeholder="请输入app名称" />
        </el-form-item>

        <el-form-item label="版本编号：" prop="appVersion">
          <el-input v-model="formData.appVersion" placeholder="请输入app版本编号" />
        </el-form-item>
        <el-form-item label="更新内容：" prop="explain">
          <el-input type="textarea" v-model="formData.explain" placeholder="请输入更新内容" />
        </el-form-item>
        <el-form-item label="强制更新：">
          <el-switch
            v-model="formData.mustReplace"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#E3E3E3"
          />
        </el-form-item>

        <el-form-item label="更新至当前版本：">
          <el-switch
            v-model="formData.replaceCurrentVersion"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#E3E3E3"
          />
        </el-form-item>

        <el-form-item label="APP文件：" prop="appPath">
          <el-upload
            drag
            action
            accept=".apk"
            :file-list="fileList"
            :on-change="change"
            :show-file-list="false"
            :http-request="uploadAppFile"
            class="upload-demo"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将.apk 文件拖到此处，或
              <em>点击上传</em>
            </div>

            <div
              v-if="fileList.length > 0"
              :style="{ width: progressWidth + '%' }"
              class="progress"
            ></div>
            <div v-if="fileList.length > 0" class="fileName">{{ fileList[0].name }}</div>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="handleRepot">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updateAppApi, uploadApp, appendApp } from "@/api/lib/api.js";
export default {
  data() {
    return {
      visible: false,
      progressWidth: 0,
      formData: {},
      fileList: [],
      rules: {
        appName: [{ required: true, trigger: "change", message: "App名称" }],
        appVersion: [
          { required: true, trigger: "change", message: "请输入app版本编号" }
        ],
        explain: [
          { required: true, trigger: "change", message: "请输入更新内容" }
        ],
        appPath: [
          { required: true, trigger: "change", message: "请上传APk文件" }
        ]
      }
    };
  },
  methods: {
    show(row = {}) {
      this.visible = true;
      this.fileList = [];
      this.formData = { ...row };
    },
    change(file) {
      this.fileList = [file];
    },
    uploadAppFile({ file }) {
      let formData = new FormData();
      formData.append("file", file);
      uploadApp(formData, this.progress).then(res => {
        if (res.code == "SUCCESS") {
          this.formData = { ...this.formData, appPath: res.data };
          return;
        }
        this.$message.error(res.msg);
        this.progressWidth = 0;
        this.fileList = [];
      });
    },
    progress(e) {
      console.log(e);
      let { loaded, total } = e;
      let prog = ((loaded / total) * 100).toFixed(0);
      this.progressWidth = prog;
    },
    //提交
    handleRepot() {
      let { id } = this.formData;
      this.$refs.Form.validate(valid => {
        if (!valid) {
          return;
        }

        let thenc = id ? updateAppApi(this.formData) : appendApp(this.formData);
        thenc.then(res => {
          if (res.code == "SUCCESS") {
            this.visible = false;
            this.$emit("finsh");
            this.formData = {};
            this.fileList = [];
            this.$message.success("操作成功！");
            return;
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-demo {
  position: relative;
}
.progress {
  position: absolute;
  height: 4px;
  bottom: 0;
  background: #336ffe;
  border-radius: 10rpx;
}
.fileName {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 12px;
}
</style>
